import './style.scss'
import { useEffect, useState, useRef } from 'react';
import MainContainer from '../MainContainer';
import Spinner from '../Spinner';
import Session from '../Session';
import Button from '../Button';
import { DbHelper } from '../../../global/dbHelper';


function Sessions({
    sessionData
}) {
    const [isLoggedin, setLoggedin] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [displayData, setDisplayData] = useState();
    //const [lastUpdated, setLastUpdated] = useState('');
    const lastUpdated = useRef('');
    //const [errStats, setErrStats] = useState({});
    const errStats = useRef({});
    const [additionalSessions, setAdditionalSessions] = useState(null);
    //const [sessions, setSessions] = useState([]);
    const sessions = useRef();

    const [user, setUser] = useState('');
    const separatlyLoadedData = {};


    // provider.setCustomParameters({
    //     'login_hint': 'user1@goexample.com'
    // });

    function getErrorsSortedByCount(errors) {
        let errorsList = Object.keys(errors);
        errorsList.sort((a, b) => errors[b] - errors[a]);
        //console.log("errorsList ", errorsList);

        return errorsList;
    }

    //merge new and existing error stats
    function mergeErrSats(errStats, newErrStats) {
        //let errSt = errStats.current;
        Object.keys(newErrStats).forEach(function (key) {
            let count = newErrStats[key];
            errStats[key] = (errStats[key] ? errStats[key] : 0) + count;
        })

        console.log("errStats: ", errStats);
        //errStats.current = errSt;
        //setErrStats(errStats);

        return errStats;
    }

    function mergeSessions(sessions, newSessions) {
        //console.log("sessions: ", sessions);
        //let mergedSessions = sessions.current;
        //let mergedSessions = [];
        if (!sessions) {
            sessions = [];
        }

        var keys = Object.keys(newSessions);

        for (let i = keys.length - 1; i >= 0; i--) {
            let id = keys[i];
            let session = newSessions[id];

            sessions.push(session);
        }

        //console.log("mergedSessions ", mergedSessions);
        //sessions.current = mergedSessions;
        //console.log("sessions: ", sessions.current);
        //setSessions(mergedSessions);

        return sessions;
    }

    function getMergedSessionsRows(sessions, additionalSessions) {
        console.log("getMergedSessionRows sessions: ", sessions);
        console.log("getMergedSessionRows additionalSessions: ", additionalSessions);

        if (sessions && sessions.data) {
            let items = [];

            var keys = Object.keys(sessions.data);
            console.log(`keys:`, keys);

            let errStats;
            let currentSessions;

            if (additionalSessions
                && additionalSessions.stats
                && additionalSessions.stats.errorStatsByType) {
                errStats = mergeErrSats(additionalSessions.stats.errorStatsByType);
                currentSessions = mergeSessions(additionalSessions.data);
            }
            else {
                errStats = mergeErrSats(sessions.stats.errorStatsByType);
                currentSessions = mergeSessions(sessions.data);
            }

            let errList = getErrorsSortedByCount(errStats);
            let lastUpdatedRec = '';

            Object.keys(currentSessions).forEach(function (key) {
                let session = currentSessions[key];

                lastUpdatedRec = session.lastUpdated;

                items.push(<Session
                    key={key}
                    session={session}
                    errList={errList}
                />
                )
            })

            console.log("lastUpdated ", lastUpdatedRec);

            lastUpdated.current = lastUpdatedRec
            //setLastUpdated(lastUpdated);

            return items;
        }
        else {
            return "no sessions found"
        }
    }
    function getSessionRows2(initialSessions) {

        if (!sessions.current && initialSessions && initialSessions.data) {
            console.log("initialSessions ", initialSessions);    
            errStats.current = mergeErrSats(errStats.current, initialSessions.stats.errorStatsByType);
            sessions.current = mergeSessions(sessions.current, initialSessions.data);
        }

        let errList = getErrorsSortedByCount(errStats.current);
        let lastUpdatedRec = '';
        let items = [];

        Object.keys(sessions.current).forEach(function (key) {
            let session = sessions.current[key];

            lastUpdatedRec = session.lastUpdated;

            items.push(<Session
                key={key}
                session={session}
                errList={errList}
            />
            )
        })

        console.log("lastUpdated ", lastUpdatedRec);

        lastUpdated.current = lastUpdatedRec

        return items;
    }

    function getSessionRows(sessions) {

        console.log("updateResult ", sessions);

        if (sessions && sessions.data) {
            let items = [];

            var keys = Object.keys(sessions.data);
            //console.log(`keys:`, keys);

            //let errList = getErrorsSortedByCount(mergeErrSats(sessions.stats.errorStatsByType));
            let errList = getErrorsSortedByCount(sessions.stats.errorStatsByType);

            let currentSessions = mergeSessions(sessions.data);
            //let currentSessions = sessions.data;

            let lastUpdated = '';

            Object.keys(currentSessions).forEach(function (key) {
                let session = currentSessions[key];

                lastUpdated = session.lastUpdated;

                items.push(<Session
                    key={key}
                    session={session}
                    errList={errList}
                />
                )
            })

            //console.log("lastUpdated ", lastUpdated);

            //setLastUpdated(lastUpdated);

            return items;
        }
        else {
            return "no sessions found"
        }
    }

    function updateError(err) {
        setLoading(false);
        setError(true);
        console.log("updateError ", err);
    }

    // function loadData() {
    //     DbHelper.fetchSessionsData(updateResult, updateError);
    // }

    function addMoreSessions(newSessions) {
        

        if (newSessions && newSessions.data) {
            errStats.current = mergeErrSats(errStats.current, newSessions.stats.errorStatsByType);
            sessions.current = mergeSessions(sessions.current, newSessions.data);
        }

        //this triggers re render of the page
        setAdditionalSessions(newSessions);
    }

    function getMoreSessions() {
        DbHelper.fetchSessionsBefore(lastUpdated.current.slice(0, -1), addMoreSessions);

    }


    return (
        <div className='sessions' >
            <div className='sessions-body'>
                <div className='sessions-body-title'>
                    Session Data
                </div>
                <div className='sessions-body-data'>
                    {getSessionRows2(sessionData)}
                </div>
                <div>
                    <Button
                        title='Show more'
                        onClick={() => {
                            getMoreSessions()
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Sessions;
