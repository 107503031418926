export const SET_PRICES = 'SET_PRICES';
export const SET_PROMOCODE = 'SET_PROMOCODE';

export const setPrices = (value) => (dispatch) => (
    dispatch({
        type: SET_PRICES,
        payload: {
            value: value
        }
    })
)

export const setPromoCode = (value) => (dispatch) => (
    dispatch({
        type: SET_PROMOCODE,
        payload: {
            value: value
        }
    })
)