import './style.scss';

import { useState } from 'react';
import { PromoCode } from '../../global/promoCode';
import { setPromoCode } from '../../redux/actions/priceAction';
import { useSelector, useDispatch } from 'react-redux';

function Price({ price, promo, priceDscrpt, imgSrc, children }) {
    //const dispatch = useDispatch();
    //const { promo } = useSelector((state) => state.price);

    const [isPriceSelected, setPriceSelected] = useState(false);

    return (
        <div className='priceFront'>
            <div className={`priceFront-background ${isPriceSelected && "showBackGround"}`}
                onClick={() => { setPriceSelected(false); }}>
            </div>
            <div className='priceFront-container' onClick={() => { setPriceSelected(true) }}>
                <div className={`priceFront-container-type ${priceDscrpt == 'Digital Copy' ? 'digitalS' : 'printedS'} showHelpIcon`}>
                    <img src={imgSrc} title={priceDscrpt} />
                    <div className='priceFront-container-type-dscrpt'>{priceDscrpt}&nbsp;-</div>
                    <div className='priceFront-container-type-values'>
                        <div className={`priceFront-container-type-values-regular ${promo > 0 && 'oldPrice'}`}>
                            ${price}
                        </div>
                        {promo > 0 &&
                            <div className='priceFront-container-type-newPrice'>${PromoCode.getDiscountedPrice(price, promo)}</div>
                        }
                    </div>
                </div>
                <div className={`priceFront-container-helpIcon ${isPriceSelected && "showToolTip"}`}>
                    <div className='priceFront-container-helpIcon-question'>
                        ?
                    </div>
                    <div className='priceFront-container-helpIcon-toolTip toolTip'>
                        <div className='priceFront-container-helpIcon-toolTip-text'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Price;