import './style.scss';

import { useState } from 'react';
import { PromoCode } from '../../global/promoCode';
import { setPromoCode } from '../../redux/actions/priceAction';
import { useSelector, useDispatch } from 'react-redux';

function PromoCodeUI() {
    const dispatch = useDispatch();
    const { promo } = useSelector((state) => state.price);

    const [isPromoCodeEditable, setPromoCodeEditable] = useState(false);
    const [promoCodeLocal, setLocalPromoCode] = useState("");
    const [promoCodeError, setPromoCodeError] = useState("");
    //const [promoCodeManuallyChanged, setPromoCodeManuallyChanged] = useState(false);
    const [promoButtonActive, setPromoButtonActive] = useState(false);
    const [isRetreivingInProcess, setRetreivingInProcess] = useState(false);

    const onPromoCodeChange = (value) => {
        //setPromoCodeManuallyChanged(true);
        const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
        setLocalPromoCode(filteredValue);
        setPromoButtonActive(filteredValue != '');
        setPromoCodeError('');
    }

    const promoCodeRetrieved = (value) => {
        if (value) {
            dispatch(setPromoCode(value));
        }
        else {
            setPromoCodeError('Promo Code Not Found');
            dispatch(setPromoCode(value));
        }

        setRetreivingInProcess(false);
        setPromoCodeEditable(false);
    }

    const checkPromoCode = () => {
        if (promoButtonActive) {
            setPromoButtonActive(false);
            setRetreivingInProcess(true);
            setPromoCodeError('');
            console.log('checkPromoCode: ' + promoCodeLocal);
            PromoCode.retrievePromoCode(promoCodeLocal, promoCodeRetrieved);
        }
    }

    return (
        <div className='promoCode'>
            {promo.code == '' || isPromoCodeEditable ?
                <div className='promoCode-main'>
                    <input
                        type="text"
                        name="PromoCode"
                        placeholder="Promo Code"
                        value={promoCodeLocal != '' ? promoCodeLocal : (promo.code == '' ? '' : promo.code)}
                        onChange={(e) => { onPromoCodeChange(e.target.value); }}
                    />
                    <div onClick={checkPromoCode} className={`promoCode-main-button ${promoButtonActive != '' && 'active'}`}>
                        {isRetreivingInProcess ? 'checking' : 'apply'}
                    </div>
                </div>
                :
                <div className='promoCode-main'>
                    <div className='promoCode-main-label'>Promo code:</div>
                    <div className='promoCode-main-code'>{promo.code}</div>
                    <div className='promoCode-main-link' onClick={() => { setPromoCodeEditable(true) }}>edit</div>
                </div>
            }
            <div className='promoCode-error'>
                {promoCodeError}
            </div>

        </div>
    )
}

export default PromoCodeUI;