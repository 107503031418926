import { DbHelper } from './dbHelper';

const promoUrl = '?promocode=';

const isPromoValueValid = (promoValue) => {
    return promoValue && promoValue > 0 && promoValue < 1;
}

const promoFetched = (promoCode) => {
    //console.log('promo code from db ' + promoCode);

    if (promoCode && (isPromoValueValid(promoCode.digital) || isPromoValueValid(promoCode.printed))) {
        let resultPromoCode = {
            code: promoCode.code
        }

        if (isPromoValueValid(promoCode.digital)) {
            resultPromoCode.digital = promoCode.digital;
        }

        if (isPromoValueValid(promoCode.printed)) {
            resultPromoCode.printed = promoCode.printed;
        }

        return resultPromoCode;
    }

    return null;
}

export class PromoCode {

    static getDiscountedPrice = (originalPrice, discount) => {
        if (!discount) {
            discount = 0;
        }

        return Math.trunc((originalPrice - originalPrice * discount) * 100) / 100
    }
    
    static retrievePromoCode = (promoCode, returnPromoCode) => {
        promoCode = promoCode.toLowerCase();
        //console.log('promo code req ' + promoCode);

        if (promoCode && promoCode.length > 0) {
            DbHelper.fetchPromoCode(promoCode, (promoValue) => { returnPromoCode(promoFetched(promoValue)) });
        }
        else {
            returnPromoCode(null);
        }
    }

    static retrievePromoCodeFromUrl = (search, returnPromoCode) => {
        search = search.toLowerCase();
        let promoCode = search.substring(search.indexOf(promoUrl) + promoUrl.length);

        //console.log('promo code req ' + promoCode);

        this.retrievePromoCode(promoCode, returnPromoCode);
    }
}