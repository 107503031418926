import './style.scss'
import { useEffect, useState, useRef } from 'react';
import MainContainer from '../../components/Basic/MainContainer';
import Spinner from '../../components/Basic/Spinner';
import Session from '../../components/Basic/Session';
import Button from '../../components/Basic/Button';
import TabButtons from '../../components/Basic/TabButtons';
import AdminDashboard from '../../components/Basic/AdminDashboard';
import Sessions from '../../components/Basic/sessions';

import { DbHelper } from '../../global/dbHelper';
import { AdminData } from '../../global/adminData';
import { getAuth, signOut, signInWithPopup, GoogleAuthProvider } from "firebase/auth";


function Monitor({

}) {
    const [isLoggedin, setLoggedin] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [displayData, setDisplayData] = useState();
    const [lastUpdated, setLastUpdated] = useState('');
    const [errStats, setErrStats] = useState({});
    const [sessions, setSessions] = useState([]);
    const [user, setUser] = useState('');
    const [performanceData, setPerformanceData] = useState();
    const [currentSessionData, setCurrentSessionData] = useState();
    const [remainingTokens, setRemainingTokens] = useState();

    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    const historyLimit = 6;
    const dashboardTab = "Dashboard";
    const ordersTab = "Orders";
    const sessionsTab = "Sessions";
    const tabList = [dashboardTab, ordersTab, sessionsTab];
    const [activeTab, setActiveTab] = useState(dashboardTab);


    // provider.setCustomParameters({
    //     'login_hint': 'user1@goexample.com'
    // });

    function getErrorsSortedByCount(errors) {
        let errorsList = Object.keys(errors);
        errorsList.sort((a, b) => errors[b] - errors[a]);
        //console.log("errorsList ", errorsList);

        return errorsList;
    }

    //merge new and existing error stats
    function mergeErrSats(errorSats) {
        let newErrStats = {};

        Object.keys(errorSats).forEach(function (key) {
            let count = errorSats[key];
            errStats[key] = (errStats[key] ? errStats[key] : 0) + count;
        })

        //console.log("newErrStats ", errorSats);
        setErrStats(errStats);

        return errStats;
    }

    function mergeSessions(newSessions) {
        let mergedSessions = sessions;

        var keys = Object.keys(newSessions);

        for (let i = keys.length - 1; i >= 0; i--) {
            let id = keys[i];
            let session = newSessions[id];

            mergedSessions.push(session);
        }

        //console.log("mergedSessions ", mergedSessions);
        setSessions(mergedSessions);

        return mergedSessions;
    }

    function updateResult(sessions) {

        console.log("updateResult ", sessions);

        let items = [];

        var keys = Object.keys(sessions.data);
        //console.log(`keys:`, keys);

        let errList = getErrorsSortedByCount(mergeErrSats(sessions.errorStatsByType));

        let currentSessions = mergeSessions(sessions.data);

        let lastUpdated = '';

        Object.keys(currentSessions).forEach(function (key) {
            let session = currentSessions[key];

            lastUpdated = session.lastUpdated;

            items.push(<Session
                key={key}
                session={session}
                errList={errList}
            />
            )
        })

        //console.log("lastUpdated ", lastUpdated);

        setLastUpdated(lastUpdated);
        setDisplayData(items);
        setLoading(false);
    }



    async function loadData() {
        //count total sessions
        //success sessions
        //total error count
        //error by type count

        let adminData = new AdminData();

        adminData.getRemainingTokens(setRemainingTokens);

        //console.log("mainStatisticData ", adminData.mainStatisticData);

        setPerformanceData(await adminData.loadInitialData());

        console.log("adminData.currentMonthSessionData ", adminData.currentMonthSessionData);

        setCurrentSessionData(adminData.currentMonthSessionData);



        setLoading(false);

        //DbHelper.setPromoCodesToDB();

        // let perfData = await DbHelper.fetchPerfStatsAfterTheDate(getDateMonthsFromNow(historyLimit));
        // console.log("fetchPerfStatsAfterTheDate ", perfData);

        // checkAndUpdateSessionDataStatsMinusMonth(perfData);

        // let currentMonthSessionData = await DbHelper.fetchAllSessionsAfterTheDate(getToday());
        // console.log("currentMonthSessionData ", currentMonthSessionData);

        // let test = await DbHelper.fetchAllSessionsInAPeriod("202402", "202403", false);
        // console.log("test ", test);

        //DbHelper.fetchSessionsData(updateResult, updateError);
    }

    function getMoreSessions() {
        DbHelper.fetchSessionsBefore(lastUpdated, updateResult);

    }

    function logOff() {
        signOut(auth).then(() => {
            // Sign-out successful.
            setLoggedin(false);
            setUser('');
        }).catch((error) => {
            setError(true)
            // An error happened.
        });
    }

    function login() {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // IdP data available using getAdditionalUserInfo(result)
                // ...
                //console.log("signInWithPopup ", result);
                //console.log("UID ", result.user.uid);
                setUser(result.user.email);
                setLoggedin(true);
                loadData();
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                setLoading(false);
                setError(true);
            });
    }

    function updateActiveButton(tabName) {
        setActiveTab(tabName);
        //console.log(`updateActiveButton: ${tabName}`);
    }

    return (
        <MainContainer>
            <div className='monitor' >
                {!isLoggedin ?
                    <div className='monitor-button' onClick={() => { login() }}>
                        Login
                    </div>
                    :
                    (isLoading ?
                        <Spinner />
                        :
                        (isError ?
                            <div>
                                Error
                            </div>
                            :
                            <div className='monitor-body'>
                                <div className='monitor-body-head'>
                                    <div className='monitor-body-head-label'>
                                        Remaining tokens:
                                    </div>
                                    <div className={`monitor-body-head-left ${(remainingTokens < 50 && remainingTokens > 25) ? 'yellow':''} ${(remainingTokens < 25) ? 'red':''}`}>
                                        {remainingTokens}
                                    </div>

                                    <div className='monitor-body-head-right'>
                                        {user}
                                        <a onClick={() => { logOff() }} >
                                            logout
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <TabButtons buttonList={tabList} updateActiveButton={(activeButton) => {
                                        updateActiveButton(activeButton);
                                    }} />
                                </div>
                                {activeTab == dashboardTab ?
                                    <div>
                                        <AdminDashboard statData={performanceData} />
                                    </div>
                                    :
                                    ""
                                }
                                {activeTab == sessionsTab ?
                                    <Sessions sessionData={currentSessionData} />
                                    :
                                    ""
                                }
                                {activeTab == ordersTab ?
                                    <div>Orders</div>
                                    :
                                    ""
                                }
                                {/* <div className='monitor-body-title'>
                                    Session Data
                                </div>
                                <div className='monitor-body-data'>
                                    {displayData}
                                </div>
                                <div>
                                    <Button
                                        title='Show more'
                                        onClick={() => {
                                            getMoreSessions()
                                        }}
                                    />
                                </div> */}
                            </div>
                        )
                    )
                }
            </div>
        </MainContainer>
    )
}

export default Monitor;
