import {
    SET_PRICES,
    SET_PROMOCODE
} from '../actions/priceAction';

const defaultPromo = {
    code: '',
    digital: 0,
    printed: 0
}

const INITIAL_STATE = {
    prices: {
        digital: 0,
        printed: 0,
        additional: 0
    },
    promo: defaultPromo
};

export default function step(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_PRICES:
            //console.log('SET_PRICES: ' + action);
            return {
                ...state,
                prices: action.payload.value
            }
        case SET_PROMOCODE:
            //console.log('SET_PROMOCODE: ' + action.payload.value);
            let val = action.payload.value ? action.payload.value : defaultPromo;
            return {
                ...state,
                promo: val
            }
        default:
            return state;
    }
}